import React, { Suspense, useLayoutEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import { ThemeProvider } from './components/ThemeContext';
import { ROUTES } from './constants';
import Layout from './containers/Layout';
import rootSaga from './sagas';
import { runSaga } from './store/store';
import GlobalLoaderModal from './components/modals/GlobalLoaderModal/GlobalLoaderModal';
import InitialPage from './pages/InitialPage/InitialPage';

const InfoPage = React.lazy(() => import('./pages/InfoPage/InfoPage'));
const AgoMintPage = React.lazy(() => import('./pages/BalancePage/AgoMintPage/AgoMintPage'));
const StorePage = React.lazy(() => import('./pages/StorePage/StorePage'));
const BalancePage = React.lazy(() => import('./pages/BalancePage/BalancePage'));
const Leaderboard = React.lazy(() => import('./pages/Leaderboard/Leaderboard'));
const EarnPage = React.lazy(() => import('./pages/EarnPage/EarnPage'));
const DailyPage = React.lazy(() => import('./pages/DailyPage/DailyPage'));
const PreSalePage = React.lazy(() => import('./pages/PreSalePage/PreSalePage'));
const Play = React.lazy(() => import('./pages/PlayPage/Play'));
const MainPage = React.lazy(() => import('./pages/MainPage/MainPage'));
const GamePage = React.lazy(() => import('./pages/PlayPage/GamePage/GamePage'));
const StakingPage = React.lazy(() => import('./pages/StakingPage/StakingPage'));
const StakePage = React.lazy(() => import('./pages/StakingPage/StakePage/StakePage'));
const MultipliersPage = React.lazy(() => import('./pages/StakingPage/MultipliersPage/MultipliersPage'));

function App() {
  const manifestUrl = `${process.env.REACT_APP_SERVICE_URL}/tonconnect-manifest.json`;

  useLayoutEffect(() => {
    const telegramWebApp = window.Telegram && window.Telegram.WebApp;
    if (telegramWebApp) {
      telegramWebApp.expand();
      telegramWebApp.ready();
      telegramWebApp.disableVerticalSwipes();
      telegramWebApp.setHeaderColor('#0C0B1A');
    }
    runSaga(rootSaga);
  }, []);

  return (
    <ThemeProvider>
      <TonConnectUIProvider manifestUrl={manifestUrl} actionsConfiguration={{
        twaReturnUrl: process.env.REACT_APP_RETURN_URL,
      }}>
        <Router>
          <Suspense fallback={<GlobalLoaderModal />}>
            <Routes>
              <Route path={ROUTES.initial} element={<Layout />}>
                <Route index element={<InitialPage />} />
                <Route path={ROUTES.main}>
                  <Route index element={<MainPage />} />
                  <Route path={ROUTES.daily} element={<DailyPage />} />
                  <Route path={ROUTES.preSale} element={<PreSalePage />} />
                  <Route path={ROUTES.earn} element={<EarnPage />} />
                  <Route path={ROUTES.balance}>
                    <Route index element={<BalancePage />} />
                    <Route path={ROUTES.mintAgo} element={<AgoMintPage />} />
                  </Route>
                  <Route path={ROUTES.store} element={<StorePage />} />
                  <Route path={ROUTES.play}>
                    <Route index element={<Play />} />
                    <Route path=":id" element={<GamePage />} />
                  </Route>
                  <Route path={ROUTES.staking}>
                    <Route index element={<StakingPage />} />
                    <Route path={ROUTES.stake} element={<StakePage />} />
                    <Route path={ROUTES.multipliers} element={<MultipliersPage />} />
                  </Route>
                  <Route path={ROUTES.leaderboard} element={<Leaderboard />} />
                  <Route path={ROUTES.info} element={<InfoPage />} />
                </Route>
              </Route>
            </Routes>
          </Suspense>
        </Router>
      </TonConnectUIProvider>
    </ThemeProvider>
  );
}

export default App;
