import { useTonAddress } from '@tonconnect/ui-react';
import { Outlet } from 'react-router-dom';
import NavBar from '../components/common/NavBar/NavBar';
import s from './Layout.module.css';
import { useEffect } from 'react';
import {
  adventurerLootboxHash,
  bronzePassHash,
  dailyRewardHash, explorerLootboxHash,
  levelHash,
  multiplierMonthHash,
  multiplierWeekHash,
  offerWallModal,
  presalePurchaseInfoModal,
  ServiceModalName, standardLootboxHash,
  steelPassHash,
  storageTaps,
  superBoostHash,
  tapPassHash,
  tonAddressName,
} from '../constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  checkBuyRewardStatus,
  checkBuySuperBoosterStatus,
  getMainData,
  getUserStatus,
  registerUser,
  saveUserWallet,
  setUserData,
  setUserToken, setUserWithoutCountry,
} from '../store/features/userSlice';
import { ServiceModal } from '../components/modals/ServiceModal/ServiceModal';
import { checkBuyLevelStatus } from '../store/features/levelSlice';
import {
  checkBuyAdventurerLootboxStatus,
  checkBuyBronzePassStatus, checkBuyExplorerLootboxStatus, checkBuyStandardLootboxStatus,
  checkBuySteelPassStatus,
  checkBuyTapPassStatus,
} from '../store/features/storeSlice';
import { checkBuyMonthMultiplierStatus, checkBuyWeekMultiplierStatus } from '../store/features/stakingSlice';
import { addServiceModal } from '../store/features/serviceModalSlice';

const Layout = () => {
  const dispatch = useDispatch();
  const {user, token, country, isBoostActive, isSuperBoostActive} = useSelector((state) => state.user);
  const isUserExist = useSelector((state) => state.user.isUserExist);
  const tonAddress = useTonAddress(true);
  const {initialLoading} = useSelector((state) => state.user);

  async function fetchAuthToken(rawData) {
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVICE_URL}/api/app-auth`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({data: rawData}),
      });
      let data = await response.json();
      if(!data.country && data?.ip){
        dispatch(setUserWithoutCountry());
        const response = await fetch(`https://ipgeolocation.abstractapi.com/v1/?api_key=4879f4acbb4d490189676378af6c0a98&ip_address=${data?.ip}`, {
          method: 'GET',
          headers: {'Content-Type': 'application/json'},
        });
        const geoData = await response.json();
        data= {...data, country:geoData.country_code}
      }
      dispatch(setUserToken(data));
    } catch (error) {
      console.error(error);
    }
  }

  const initUser = async () => {
    const telegramWebApp = window.Telegram && window.Telegram.WebApp;
    if (telegramWebApp) {
      const userData = telegramWebApp.initDataUnsafe.user;
      const rawData = telegramWebApp.initData;
      if (userData && rawData) {
        const userInfo = {
          firstName: userData.first_name,
          lastName: userData.last_name,
          username: userData.username,
          id: userData.id,
          isPremium: userData.is_premium,
        };
        dispatch(setUserData(userInfo));
        if (process.env.NODE_ENV !== 'production') {
          const {fetchDevAuthToken} = await import('../utils/devAuth');
          let authData = await fetchDevAuthToken(userData);
          if(!authData.country && authData?.ip){
            const response = await fetch(`https://ipgeolocation.abstractapi.com/v1/?api_key=4879f4acbb4d490189676378af6c0a98&ip_address=${authData?.ip}`, {
              method: 'GET',
              headers: {'Content-Type': 'application/json'},
            });
            const geoData = await response.json();
            authData= {...authData, country:geoData.country_code}
          }
          dispatch(setUserToken(authData));
        } else {
          await fetchAuthToken(rawData);
        }
        telegramWebApp.expand();
      }
    } else {
      console.log('This code is not running inside a Telegram Web App environment.');
    }
  };

  useEffect(() => {
    localStorage.setItem(storageTaps, '0');
    // dispatch(addServiceModal({ name: ServiceModalName.maintenanceModal, payload: {} }))
  }, []);

  // useEffect(() => {
  //   const lastShownDate = localStorage.getItem(offerWallModalDate);
  //   const today = new Date().toISOString().split('T')[0];
  //   if (lastShownDate !== today && levelData) {
  //     dispatch(addServiceModal({ name: ServiceModalName.offerWallModal, payload: {} }));
  //     localStorage.setItem(offerWallModalDate, today);
  //   }
  // }, [levelData]);


  // useEffect(() => {
  //   const fetchCountry = async () => {
  //     try {
  //       const response = await fetch(`https://www.googleapis.com/geolocation/v1/geolocate?key=${process.env.REACT_APP_GEOLOCATION_GOOGLE_API_KEY}`, {
  //         method: 'POST',
  //       });
  //       const data = await response.json();
  //       const { lat, lng } = data.location;
  //
  //       const countryResponse = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GEOLOCATION_GOOGLE_API_KEY}`);
  //       const countryData = await countryResponse.json();
  //
  //       const countryResult = countryData.results.find(result =>
  //         result.types.includes('country')
  //       );
  //       dispatch(setUserCountry(countryResult?.address_components[0]?.short_name || 'Country not found'))
  //     } catch (error) {
  //       console.error('Error fetching country:', error);
  //     }
  //   };
  //
  //   fetchCountry();
  // }, []);

  useEffect(() => {
    localStorage.removeItem(offerWallModal);

    if (!token) {
      initUser();
    } else {
      dispatch(getUserStatus());
    }
  }, [token]);

  useEffect(() => {
    if (token && user && typeof isUserExist === 'boolean' && !isUserExist) {
      const urlParams = new URLSearchParams(window.location.search);
      const startAppParam = urlParams.get('tgWebAppStartParam');
      dispatch(registerUser(startAppParam));
    }
  }, [token, user, isUserExist]);

  useEffect(() => {
    if (isUserExist) {
      dispatch(getMainData());
    }
  }, [isUserExist]);

  useEffect(() => {
    const savedTonAddress = localStorage.getItem(tonAddressName);
    if (token && user && tonAddress && savedTonAddress !== tonAddress) {
      localStorage.setItem(tonAddressName, tonAddress);
      dispatch(saveUserWallet(tonAddress));
    }
  }, [tonAddress, token, user]);

  useEffect(() => {
    if (initialLoading) return;
    const lootboxStandardHash = localStorage.getItem(standardLootboxHash);
    const lootboxAdventurerHash = localStorage.getItem(adventurerLootboxHash);
    const lootboxExplorerHash = localStorage.getItem(explorerLootboxHash);
    const superBoosterHash = localStorage.getItem(superBoostHash);
    const bronzeHash = localStorage.getItem(bronzePassHash);
    const steelHash = localStorage.getItem(steelPassHash);
    const lvlHash = localStorage.getItem(levelHash);
    const tapHash = localStorage.getItem(tapPassHash);
    const dailyHash = localStorage.getItem(dailyRewardHash);
    const stakingMultiplierWeekHash = localStorage.getItem(multiplierWeekHash);
    const stakingMultiplierMonthHash = localStorage.getItem(multiplierMonthHash);
    const presaleModal = localStorage.getItem(presalePurchaseInfoModal);
    if (presaleModal) {
      dispatch(addServiceModal({
        name: ServiceModalName.successModal,
        payload: {
          title: 'The transaction has been initiated',
          text: 'Please follow to your wallet transaction history for more detail.',
          btnCallback: () => localStorage.removeItem(presalePurchaseInfoModal),
        },
      }));
    }
    if (lootboxStandardHash) {
      dispatch(checkBuyStandardLootboxStatus('ton'));
    }
    if (lootboxAdventurerHash) {
      dispatch(checkBuyAdventurerLootboxStatus('ton'));
    }
    if (lootboxExplorerHash) {
      dispatch(checkBuyExplorerLootboxStatus('ton'));
    }
    if (superBoosterHash) {
      dispatch(checkBuySuperBoosterStatus('ton'));
    }
    if (dailyHash) {
      dispatch(checkBuyRewardStatus('ton'));
    }
    if (steelHash) {
      dispatch(checkBuySteelPassStatus('ton'));
    }
    if (bronzeHash) {
      dispatch(checkBuyBronzePassStatus('ton'));
    }
    if (lvlHash) {
      dispatch(checkBuyLevelStatus('ton'));
    }
    if (tapHash) {
      dispatch(checkBuyTapPassStatus('ton'));
    }
    if (stakingMultiplierWeekHash) {
      dispatch(checkBuyWeekMultiplierStatus('ton'));
    }
    if (stakingMultiplierMonthHash) {
      dispatch(checkBuyMonthMultiplierStatus('ton'));
    }
  }, [initialLoading]);

  return (
    <div className={s.layout}>
      <ServiceModal />
      <Outlet />
      {!isBoostActive && !isSuperBoostActive && <NavBar />}
    </div>
  );
};

export default Layout;
