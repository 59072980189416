import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import {
  buyTapPass,
  cancelLootboxLoading, cancelLootboxOpenLoading,
  cancelPassesLoading,
  cancelTapPassesLoading,
  checkBuyAdventurerLootboxStatus,
  checkBuyBronzePassStatus,
  checkBuyExplorerLootboxStatus,
  checkBuySilverPassStatus,
  checkBuyStandardLootboxStatus,
  checkBuySteelPassStatus,
  checkBuyTapPassStatus,
  getLootboxes,
  getPassesInfo,
  getSilverPassInfo,
  getTapPassesInfo,
  openLootbox,
  setLootboxes,
  setPassesInfo,
  setSilverPassInfo,
  setTapPassesInfo,
} from '../store/features/storeSlice';
import {
  adventurerLootboxHash,
  adventurerLootboxHashPrice,
  adventurerLootboxInfoModalStatus,
  bronzePassHash,
  bronzePassHashPrice,
  bronzePassInfoModalStatus,
  explorerLootboxHash,
  explorerLootboxHashPrice,
  explorerLootboxInfoModalStatus,
  levelInfoModalStatus,
  ServiceModalName,
  silverPassHash,
  silverPassHashPrice,
  silverPassInfoModalStatus,
  standardLootboxHash,
  standardLootboxHashPrice,
  standardLootboxInfoModalStatus,
  steelPassHash,
  steelPassHashPrice,
  steelPassInfoModalStatus,
  tapPassHash,
  tapPassHashPrice,
  tapPassInfoModalStatus,
} from '../constants';
import { addServiceModal, removeServiceModal } from '../store/features/serviceModalSlice';
import { sendGTMEvent, sendGTMPurchaseEvent } from '../utils';
import { getUserBalanceWithItems, getUserTaps } from '../store/features/userSlice';

export function* getPassesSaga() {
  const token = yield select((state) => state.user.token);
  if (token) {
    try {
      const response = yield call(fetch, `${process.env.REACT_APP_SERVICE_URL}/api/passes/left`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      });
      const data = yield response.json();

      const preparedData = {
        bronze: {
          count: data.bronze.count,
          max: data.bronze.max,
          price: data.bronze.price,
          priceTon: data.bronze.price_ton,
          priceStars: data.bronze.price_stars,
          soldOut: data.bronze.sold_out,
        },
        steel: {
          count: data.steel.count,
          max: data.steel.max,
          price: data.steel.price,
          priceTon: data.steel.price_ton,
          priceStars: data.steel.price_stars,
          soldOut: data.steel.sold_out,
        },
      };
      yield put(setPassesInfo(preparedData));
    } catch (e) {
      console.error(e);
      yield put({type: cancelPassesLoading.type});
    }
  }
}

export function* getLootboxesSaga() {
  const token = yield select((state) => state.user.token);
  if (token) {
    try {
      const response = yield call(fetch, `${process.env.REACT_APP_SERVICE_URL}/api/lootbox/available`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      });
      const data = yield response.json();
      yield put(setLootboxes(data));
    } catch (e) {
      console.error(e);
      yield put({type: cancelLootboxLoading.type});
    }
  }
}

export function* openLootboxSaga({payload}) {
  const token = yield select((state) => state.user.token);
  if (token) {
    try {
      const response = yield call(fetch, `${process.env.REACT_APP_SERVICE_URL}/api/lootbox/open`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify({
          lootbox_id: payload.id,
        }),
      });
      if(payload.status === 'free') {
        sendGTMEvent(`open_standard_free_lootbox`, 'button', 'click', 'Click open button');
      } else {
        sendGTMEvent(`open_${payload.type}_lootbox`, 'button', 'click', 'Click open button');
      }
      const data = yield response.json();
      switch (data.reward) {
        case 'ago_tokens':
        case 'steelpass':
        case 'bronzepass':
        case 'silverpass':
          yield put(getUserBalanceWithItems());
          break;
        case '1k_tap_pass':
        case '5k_tap_pass':
        case '10k_tap_pass':
          yield put(getTapPassesInfo());
          yield put(getUserTaps());
          break;
        case 'paid_booster':
          yield put(getUserTaps());
          break;
        default:
          break;
      }
      yield put(getLootboxes());
      yield put(addServiceModal({
        name:ServiceModalName.lootBoxClaimModal,
        payload:{lootboxType: payload.type, reward: data.reward, rewardValue: data.reward_value}
      }));
    } catch (e) {
      console.error(e);
      yield put({type: cancelLootboxOpenLoading.type});
    }
  }
}

export function* getSilverPassSaga() {
  const token = yield select((state) => state.user.token);
  if (token) {
    try {
      const response = yield call(fetch, `${process.env.REACT_APP_SERVICE_URL}/api/silverpass`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      });
      const data = yield response.json();

      const preparedData = {
        active: {tonPrice: data.active.ton_price},
        prev: {tonPrice: data.prev.ton_price},
        next: {tonPrice: data.next.ton_price},
      };
      yield put(setSilverPassInfo(preparedData));
    } catch (e) {
      console.error(e);
      yield put({type: cancelTapPassesLoading.type});
    }
  }
}

export function* getTapPassesSaga() {
  const token = yield select((state) => state.user.token);
  if (token) {
    try {
      const response = yield call(fetch, `${process.env.REACT_APP_SERVICE_URL}/api/get-tap-passes`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      });
      const data = yield response.json();
      const preparedData = {
        activePass: data?.active_tap_pass?.name ? {
          createdAt: data?.active_tap_pass?.created_at,
          expiresAt: data?.active_tap_pass?.expires_at,
          name: data?.active_tap_pass?.name,
        } : null,
        isAvailableForAgo: data?.for_ago_available,
        passes: {
          firstPass: {
            price: data.tap_passes['1_day'].user_cost,
            tonPrice: data.tap_passes['1_day'].price_ton,
            usdPrice: data.tap_passes['1_day'].price_usd,
            starsPrice: data.tap_passes['1_day'].price_stars,
          },
          secondPass: {
            price: data.tap_passes['3_days'].user_cost,
            tonPrice: data.tap_passes['3_days'].price_ton,
            usdPrice: data.tap_passes['3_days'].price_usd,
            starsPrice: data.tap_passes['3_days'].price_stars,
          },
          thirdPass: {
            price: data.tap_passes['7_days'].user_cost,
            tonPrice: data.tap_passes['7_days'].price_ton,
            usdPrice: data.tap_passes['7_days'].price_usd,
            starsPrice: data.tap_passes['7_days'].price_stars,
          },
        },
      };
      yield put(setTapPassesInfo(preparedData));
    } catch (e) {
      console.error(e);
      yield put({type: cancelTapPassesLoading.type});
    }
  }
}

function* buyTapPassSaga({payload}) {
  const token = yield select((state) => state.user.token);
  if (token) {
    try {
      yield call(fetch, `${process.env.REACT_APP_SERVICE_URL}/api/buy-tap-passes`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify({
          name: payload,
        }),
      });
      yield put({type: getTapPassesInfo.type});
      yield put({type: getUserTaps.type});
      yield put({type: getUserBalanceWithItems.type});
    } catch (e) {
      console.error(e);
      yield put({type: cancelTapPassesLoading.type});
    }
  }
}

export function* checkBuyTapPassStatusSaga({payload}) {
  const token = yield select((state) => state.user.token);
  const hash = localStorage.getItem(tapPassHash);
  const hashPrice = localStorage.getItem(tapPassHashPrice);

  if (token && hash) {
    try {
      let isCompleted = false;
      let count = 0;
      while (!isCompleted) {
        const tapPassInfoModal = localStorage.getItem(tapPassInfoModalStatus);

        const response = yield call(fetch, `${process.env.REACT_APP_SERVICE_URL}/api/orders/status?order=${hash}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
          },
        });
        const data = yield response.json();
        if (data.status === 'closed' || data.status === 'confirmed') {
          sendGTMEvent(`finish_buying_tap_pass_for_${payload}`, 'button', 'click', 'Finish tap pass transaction', +hashPrice);
          sendGTMPurchaseEvent(hash, +hashPrice, 'USD');
          localStorage.removeItem(tapPassHash);
          localStorage.removeItem(tapPassHashPrice);
          localStorage.removeItem(tapPassInfoModalStatus);
          isCompleted = true;
          if (payload === 'stars') {
            yield put(removeServiceModal(ServiceModalName.paymentMethodModal));
          } else {
            yield put(removeServiceModal(ServiceModalName.transactionProcessModal));
          }
          yield put(getUserTaps());
          yield put({type: getTapPassesInfo.type});
        } else if (count > 10 || +tapPassInfoModal > 10) {
          count = 0;
          isCompleted = true;
          sendGTMEvent(`abort_buying_tap_pass_for_${payload}`, 'notification', 'show', 'Abort tap pass transaction', +hashPrice);
          localStorage.removeItem(tapPassHash);
          localStorage.removeItem(tapPassHashPrice);
          localStorage.removeItem(tapPassInfoModalStatus);
        } else {
          yield delay(payload === 'ton' ? 9000 : 1000);
          count++;
          if (payload === 'ton') {
            localStorage.setItem(levelInfoModalStatus, ( +tapPassInfoModal + 1 ).toString());
          }
        }
      }
    } catch (e) {
      console.error(e);
    }
  }
}

export function* checkBuySilverPassStatusSaga() {
  const token = yield select((state) => state.user.token);
  const hash = localStorage.getItem(silverPassHash);
  const hashPrice = localStorage.getItem(silverPassHashPrice);

  if (token && hash) {
    try {
      let isCompleted = false;
      let count = 0;
      while (!isCompleted) {
        const isModalShown = localStorage.getItem(silverPassInfoModalStatus);

        const response = yield call(fetch, `${process.env.REACT_APP_SERVICE_URL}/api/orders/status?order=${hash}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
          },
        });
        if (count === 0 && !isModalShown) {
          localStorage.setItem(silverPassInfoModalStatus, 'true');
          yield put(addServiceModal({
            name: ServiceModalName.transactionProcessModal,
            payload: {
              title: 'Transaction in progress',
              text: 'Your pass will be added immediately after the transaction is completed.',
            },
          }));
        }
        const data = yield response.json();
        if (data.status === 'confirmed' || data.status === 'closed') {
          sendGTMEvent('finish_buying_silver_pass_for_ton', 'button', 'click', 'Finish tap pass transaction', +hashPrice);
          sendGTMPurchaseEvent(hash, +hashPrice, 'USD');
          localStorage.removeItem(silverPassHash);
          localStorage.removeItem(silverPassInfoModalStatus);
          isCompleted = true;
          yield put({type: getUserBalanceWithItems.type});
        } else if (count > 10 || data.status === 'closed') {
          count = 0;
          isCompleted = true;
          localStorage.removeItem(silverPassHash);
          sendGTMEvent('abort_buying_silver_pass_for_ton', 'notification', 'show', 'Abort tap pass transaction', +hashPrice);
          localStorage.removeItem(silverPassInfoModalStatus);
        } else {
          yield delay(9000);
          count++;
        }
      }
    } catch (e) {
      console.error(e);
    }
  }
}

export function* checkBuyBronzePassStatusSaga({payload}) {
  const token = yield select((state) => state.user.token);
  const hash = localStorage.getItem(bronzePassHash);
  const hashPrice = localStorage.getItem(bronzePassHashPrice);

  if (token && hash) {
    try {
      let isCompleted = false;
      let count = 0;
      while (!isCompleted) {
        const isModalShown = localStorage.getItem(bronzePassInfoModalStatus);

        const response = yield call(fetch, `${process.env.REACT_APP_SERVICE_URL}/api/orders/status?order=${hash}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
          },
        });
        if (count === 0 && !isModalShown && payload === 'ton') {
          localStorage.setItem(bronzePassInfoModalStatus, 'true');
          yield put(addServiceModal({
            name: ServiceModalName.transactionProcessModal,
            payload: {
              title: 'Transaction in progress',
              text: 'Your pass will be added immediately after the transaction is completed.',
            },
          }));
        }
        const data = yield response.json();
        if (data.status === 'closed') {
          sendGTMEvent(`finish_buying_BRONZEPASS_for_${payload}`, 'button', 'click', 'Finish tap pass transaction', +hashPrice);
          sendGTMPurchaseEvent(hash, +hashPrice, 'USD');
          localStorage.removeItem(bronzePassHash);
          localStorage.removeItem(bronzePassHashPrice);
          localStorage.removeItem(bronzePassInfoModalStatus);
          if (payload === 'stars') {
            yield put(removeServiceModal(ServiceModalName.paymentMethodModal));
          }
          isCompleted = true;
          yield put({type: getUserBalanceWithItems.type});
        } else if (count > 10 || data?.status === 'expired') {
          count = 0;
          isCompleted = true;
          localStorage.removeItem(bronzePassHash);
          localStorage.removeItem(bronzePassHashPrice);
          localStorage.removeItem(bronzePassInfoModalStatus);
          sendGTMEvent(`abort_buying_BRONZEPASS_for_${payload}`, 'notification', 'show', 'Abort tap pass transaction', +hashPrice);
        } else {
          yield delay(payload === 'ton' ? 9000 : 1000);
          count++;
        }
      }
    } catch (e) {
      console.error(e);
    }
  }
}

export function* checkBuyStandardLootboxStatusSaga({payload}) {
  const token = yield select((state) => state.user.token);
  const hash = localStorage.getItem(standardLootboxHash);
  const hashPrice = localStorage.getItem(standardLootboxHashPrice);

  if (token && hash) {
    try {
      let isCompleted = false;
      let count = 0;
      while (!isCompleted) {
        const isModalShown = localStorage.getItem(standardLootboxInfoModalStatus);

        const response = yield call(fetch, `${process.env.REACT_APP_SERVICE_URL}/api/orders/status?order=${hash}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
          },
        });
        if (count === 0 && !isModalShown && payload === 'ton') {
          localStorage.setItem(standardLootboxInfoModalStatus, 'true');
          yield put(addServiceModal({
            name: ServiceModalName.transactionProcessModal,
            payload: {
              title: 'Transaction in progress',
              text: 'Your lootbox will be added immediately after the transaction is completed.',
            },
          }));
        }
        const data = yield response.json();
        if (data.status === 'closed') {
          sendGTMEvent(`finish_buying_standard_lootbox_for_${payload}`, 'button', 'click', 'Finish tap pass transaction', +hashPrice);
          sendGTMPurchaseEvent(hash, +hashPrice, 'USD');
          localStorage.removeItem(standardLootboxHash);
          localStorage.removeItem(standardLootboxHashPrice);
          localStorage.removeItem(standardLootboxInfoModalStatus);
          if (payload === 'stars') {
            yield put(removeServiceModal(ServiceModalName.paymentMethodModal));
          }
          isCompleted = true;
          yield put(getLootboxes());
        } else if (count > 10 || data?.status === 'expired') {
          count = 0;
          isCompleted = true;
          localStorage.removeItem(standardLootboxHash);
          localStorage.removeItem(standardLootboxHashPrice);
          localStorage.removeItem(standardLootboxInfoModalStatus);
          sendGTMEvent(`abort_buying_standard_lootbox_for_${payload}`, 'notification', 'show', 'Abort tap pass transaction', +hashPrice);
        } else {
          yield delay(payload === 'ton' ? 9000 : 1000);
          count++;
        }
      }
    } catch (e) {
      console.error(e);
    }
  }
}

export function* checkBuyExplorerLootboxStatusSaga({payload}) {
  const token = yield select((state) => state.user.token);
  const hash = localStorage.getItem(explorerLootboxHash);
  const hashPrice = localStorage.getItem(explorerLootboxHashPrice);

  if (token && hash) {
    try {
      let isCompleted = false;
      let count = 0;
      while (!isCompleted) {
        const isModalShown = localStorage.getItem(explorerLootboxInfoModalStatus);

        const response = yield call(fetch, `${process.env.REACT_APP_SERVICE_URL}/api/orders/status?order=${hash}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
          },
        });
        if (count === 0 && !isModalShown && payload === 'ton') {
          localStorage.setItem(explorerLootboxInfoModalStatus, 'true');
          yield put(addServiceModal({
            name: ServiceModalName.transactionProcessModal,
            payload: {
              title: 'Transaction in progress',
              text: 'Your lootbox will be added immediately after the transaction is completed.',
            },
          }));
        }
        const data = yield response.json();
        if (data.status === 'closed') {
          sendGTMEvent(`finish_buying_explorer_lootbox_for_${payload}`, 'button', 'click', 'Finish lootbox transaction', +hashPrice);
          sendGTMPurchaseEvent(hash, +hashPrice, 'USD');
          localStorage.removeItem(explorerLootboxHash);
          localStorage.removeItem(explorerLootboxHashPrice);
          localStorage.removeItem(explorerLootboxInfoModalStatus);
          if (payload === 'stars') {
            yield put(removeServiceModal(ServiceModalName.paymentMethodModal));
          }
          isCompleted = true;
          yield put(getLootboxes());
        } else if (count > 10 || data?.status === 'expired') {
          count = 0;
          isCompleted = true;
          localStorage.removeItem(explorerLootboxHash);
          localStorage.removeItem(explorerLootboxHashPrice);
          localStorage.removeItem(explorerLootboxInfoModalStatus);
          sendGTMEvent(`abort_buying_explorer_lootbox_for_${payload}`, 'notification', 'show', 'Abort lootbox transaction', +hashPrice);
        } else {
          yield delay(payload === 'ton' ? 9000 : 1000);
          count++;
        }
      }
    } catch (e) {
      console.error(e);
    }
  }
}

export function* checkBuyAdventurerLootboxStatusSaga({payload}) {
  const token = yield select((state) => state.user.token);
  const hash = localStorage.getItem(adventurerLootboxHash);
  const hashPrice = localStorage.getItem(adventurerLootboxHashPrice);

  if (token && hash) {
    try {
      let isCompleted = false;
      let count = 0;
      while (!isCompleted) {
        const isModalShown = localStorage.getItem(adventurerLootboxInfoModalStatus);

        const response = yield call(fetch, `${process.env.REACT_APP_SERVICE_URL}/api/orders/status?order=${hash}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
          },
        });
        if (count === 0 && !isModalShown && payload === 'ton') {
          localStorage.setItem(adventurerLootboxInfoModalStatus, 'true');
          yield put(addServiceModal({
            name: ServiceModalName.transactionProcessModal,
            payload: {
              title: 'Transaction in progress',
              text: 'Your lootbox will be added immediately after the transaction is completed.',
            },
          }));
        }
        const data = yield response.json();
        if (data.status === 'closed') {
          sendGTMEvent(`finish_buying_adventurer_lootbox_for_${payload}`, 'button', 'click', 'Finish lootbox transaction', +hashPrice);
          sendGTMPurchaseEvent(hash, +hashPrice, 'USD');
          localStorage.removeItem(adventurerLootboxHash);
          localStorage.removeItem(adventurerLootboxHashPrice);
          localStorage.removeItem(adventurerLootboxInfoModalStatus);
          if (payload === 'stars') {
            yield put(removeServiceModal(ServiceModalName.paymentMethodModal));
          }
          isCompleted = true;
          yield put(getLootboxes());
        } else if (count > 10 || data?.status === 'expired') {
          count = 0;
          isCompleted = true;
          localStorage.removeItem(adventurerLootboxHash);
          localStorage.removeItem(adventurerLootboxHashPrice);
          localStorage.removeItem(adventurerLootboxInfoModalStatus);
          sendGTMEvent(`abort_buying_adventurer_lootbox_for_${payload}`, 'notification', 'show', 'Abort lootbox transaction', +hashPrice);
        } else {
          yield delay(payload === 'ton' ? 9000 : 1000);
          count++;
        }
      }
    } catch (e) {
      console.error(e);
    }
  }
}

export function* checkBuySteelPassStatusSaga({payload}) {
  const token = yield select((state) => state.user.token);
  const hash = localStorage.getItem(steelPassHash);
  const hashPrice = localStorage.getItem(steelPassHashPrice);

  if (token && hash) {
    try {
      let isCompleted = false;
      let count = 0;
      while (!isCompleted) {
        const isModalShown = localStorage.getItem(steelPassInfoModalStatus);

        const response = yield call(fetch, `${process.env.REACT_APP_SERVICE_URL}/api/orders/status?order=${hash}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
          },
        });
        if (count === 0 && !isModalShown && payload === 'ton') {
          localStorage.setItem(steelPassInfoModalStatus, 'true');
          yield put(addServiceModal({
            name: ServiceModalName.transactionProcessModal,
            payload: {
              title: 'Transaction in progress',
              text: 'Your pass will be added immediately after the transaction is completed.',
            },
          }));
        }
        const data = yield response.json();
        if (data.status === 'confirmed' || data.status === 'closed') {
          sendGTMEvent(`finish_buying_STEELPASS_for_${payload}`, 'button', 'click', 'Finish tap pass transaction', +hashPrice);
          sendGTMPurchaseEvent(hash, +hashPrice, 'USD');
          localStorage.removeItem(steelPassHash);
          localStorage.removeItem(steelPassHashPrice);
          localStorage.removeItem(steelPassInfoModalStatus);
          if (payload === 'stars') {
            yield put(removeServiceModal(ServiceModalName.paymentMethodModal));
          }
          isCompleted = true;
          yield put({type: getUserBalanceWithItems.type});
        } else if (count > 10 || data?.status === 'expired') {
          count = 0;
          isCompleted = true;
          localStorage.removeItem(steelPassHash);
          localStorage.removeItem(steelPassHashPrice);
          localStorage.removeItem(steelPassInfoModalStatus);
          sendGTMEvent(`abort_buying_STEELPASS_for_${payload}`, 'notification', 'show', 'Abort tap pass transaction', +hashPrice);
        } else {
          yield delay(payload === 'ton' ? 9000 : 1000);
          count++;
        }
      }
    } catch (e) {
      console.error(e);
    }
  }
}


export function* storeSagaWatcher() {
  yield takeLatest([openLootbox.type], openLootboxSaga);
  yield takeLatest([getLootboxes.type], getLootboxesSaga);
  yield takeLatest([getPassesInfo.type], getPassesSaga);
  yield takeLatest([getTapPassesInfo.type], getTapPassesSaga);
  yield takeLatest([getSilverPassInfo.type], getSilverPassSaga);
  yield takeLatest([buyTapPass.type], buyTapPassSaga);
  yield takeLatest([checkBuyTapPassStatus.type], checkBuyTapPassStatusSaga);
  yield takeLatest([checkBuySilverPassStatus.type], checkBuySilverPassStatusSaga);
  yield takeLatest([checkBuyBronzePassStatus.type], checkBuyBronzePassStatusSaga);
  yield takeLatest([checkBuySteelPassStatus.type], checkBuySteelPassStatusSaga);
  yield takeLatest([checkBuyStandardLootboxStatus.type], checkBuyStandardLootboxStatusSaga);
  yield takeLatest([checkBuyExplorerLootboxStatus.type], checkBuyExplorerLootboxStatusSaga);
  yield takeLatest([checkBuyAdventurerLootboxStatus.type], checkBuyAdventurerLootboxStatusSaga);
}
