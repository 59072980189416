export const ServiceModalName = {
  warningModal: 'WARNING_MODAL',
  transactionProcessModal: 'TRANSACTION_PROCESS_MODAL',
  successModal: 'SUCCESS_MODAL',
  paymentMethodModal: 'PAYMENT_METHOD_MODAL',
  offerWallModal: 'OFFER_WALL_MODAL',
  agoPaymentModal: 'AGO_PAYMENT_MODAL',
  dailyBonusModal: 'DAILY_BONUS_MODAL',
  friendLevelUpBonusModal: 'FRIEND_LEVEL_UP_BONUS_MODAL',
  inviteBonusModal: 'INVITE_BONUS_MODAL',
  leaderboardPrizesModal: 'LEADERBOARD_PRIZES_MODAL',
  levelInfo: 'LEVEL_INFO_MODAL',
  comboModal: 'COMBO_MODAL',
  comboCompleteModal: 'COMBO_COMPLETE_MODAL',
  boostModal: 'BOOST_MODAL',
  boostInfoModal: 'BOOST_INFO_MODAL',
  superBoostInfoModal: 'SUPER_BOOST_INFO_MODAL',
  superBoostPurchaseModal: 'SUPER_BOOST_PURCHASE_MODAL',
  lootBoxClaimModal: 'LOOT_BOX_CLAIM_MODAL',
  maintenanceModal: 'MAINTENANCE_MODAL',
}

export const PaymentMethodModalHashName = {
  lootboxStandard: 'STANDARD_LOOTBOX',
  lootboxExplorer: 'EXPLORER_LOOTBOX',
  lootboxAdventurer: 'ADVENTURER_LOOTBOX',
  superBooster: 'SUPER_BOOST',
  bronzePass: 'BRONZEPASS',
  steelPass: 'STEELPASS',
  level: 'LEVEL',
  tapPass: 'TAP_PASS',
  dailyReward: 'DAILY_REWARD',
  gameProduct: 'GAME_PRODUCT',
  multiplierWeek: 'MULTIPLIER_WEEK',
  multiplierMonth: 'MULTIPLIER_MONTH',
  presaleTokenXHC: 'PRESALE_TOKEN_XHC',
}