import s from './NavBarButton.module.css';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../../constants';
import { sendGTMEvent } from '../../../../../utils';

function NavBarButton({
                        name,
                        Asset,
                        path,
                        disabled,
                        isActive,
                        customIcon,
                        notice,
                      }) {

  const getGtmData = () => {
    switch (path.substring(1)) {
      case ROUTES.play :
        return {
          name: 'open_launcher_page',
          label: 'Opening the launcher page',
        };
      case ROUTES.store :
        return {
          name: 'open_store_page',
          label: 'Opening the store page',
        };
      case ROUTES.earn :
        return {
          name: 'open_earn_page',
          label: 'Opening the earn page',
        };
      case ROUTES.level :
        return {
          name: 'open_level_page',
          label: 'Opening the level page',
        };
      default:
        return {
          name: 'open_main_page',
          label: 'Opening the main page',
        };
    }
  };
  const handleClick = () => {
    const eventData = getGtmData();
    window.Telegram.WebApp.HapticFeedback.impactOccurred('medium')
    sendGTMEvent(eventData.name, 'button', 'click', eventData.label);
    navigate(path);
  };

  const navigate = useNavigate();
  return (
    <div className={`${s.navBtn} ${isActive && s.activeClass}`}
         onClick={!disabled ? handleClick : undefined}>
      {Asset && <Asset />}
      {customIcon && customIcon}
      {notice && <div className={s.dot} />}
      {name && <span>{name}</span>}
    </div>
  );
}

export default NavBarButton;
