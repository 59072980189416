import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: null,
  earnedData: null,
  prizeData: null,
  userInfo: null,
  userEarnedInfo: null,
  loading: false,
};

export const leaderboardSlice = createSlice({
  name: 'leaderboard',
  initialState,
  reducers: {
    getLeaderboardData: () => {},
    getLeaderboardPrizeData: () => {},
    getUserLeaderboardData: (state) => {
      state.loading = true;
    },
    clearLeaderboardAfterLvlUpgrade: (state,action) => {
      delete state.data[action.payload];
      delete state.data[action.payload-1];
      delete state.data[action.payload+1];
    },
    setUserLeaderboardData: (state, action) => {
      state.userInfo = action.payload.userInfo;
      state.userEarnedInfo = action.payload.userEarnedInfo;
      state.earnedData = action.payload.earnedData;
      state.data = action.payload.data;
      state.loading = false;
    },
    setLeaderboardPrizeData: (state, action) => {
      state.prizeData = { ...state.prizeData, ...action.payload }
    },
    setLeaderboardData: (state, action) => {
      state.data = { ...state.data, ...action.payload.data }
      state.earnedData = { ...state.earnedData, ...action.payload.earnedData }
    },
    cancelLeaderboardLoading: (state) => {
      state.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getLeaderboardPrizeData,
  setLeaderboardPrizeData,
  clearLeaderboardAfterLvlUpgrade,
  getUserLeaderboardData,
  setUserLeaderboardData,
  cancelLeaderboardLoading,
  getLeaderboardData,
  setLeaderboardData,
} = leaderboardSlice.actions;

const leaderboardReducer = leaderboardSlice.reducer;

export default leaderboardReducer;
