import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  lootboxLoading: false,
  tapPassesLoading: false,
  passTabLoading: false,
  lootboxOpenLoading: false,
  tapPassInfo: null,
  silverPassInfo: null,
  passesInfo: null,
  lootboxInfo: null,
};

export const storeSlice = createSlice({
  name: 'store',
  initialState,
  reducers: {
    getPassesInfo: (state) => {
      state.passTabLoading = true;
    },
    setPassesInfo: (state, action) => {
      state.passesInfo = action.payload;
      state.passTabLoading = false;
    },
    cancelPassesLoading: (state) => {
      state.passTabLoading = false;
    },
    getSilverPassInfo: (state) => {
      state.loading = true;
    },
    setSilverPassInfo: (state, action) => {
      state.silverPassInfo = action.payload;
      state.loading = false;
    },
    getTapPassesInfo: (state) => {
      state.tapPassesLoading = true;
    },
    setTapPassesInfo: (state, action) => {
      state.tapPassInfo = action.payload;
      state.tapPassesLoading = false;
    },
    cancelTapPassesLoading: (state) => {
      state.tapPassesLoading = false;
    },
    buyTapPass: (state) => {
      state.tapPassesLoading = true;
    },
    checkBuyTapPassStatus: (state) => {
      state.tapPassesLoading = true;
    },
    getLootboxes: (state) => {
      state.lootboxLoading = true;
    },
    setLootboxes: (state, action) => {
      state.lootboxLoading = false;
      state.lootboxOpenLoading = false;
      state.lootboxInfo = action.payload;
    },
    cancelLootboxLoading: (state) => {
      state.lootboxLoading = false;
    },
    cancelLootboxOpenLoading: (state) => {
      state.lootboxOpenLoading = false;
    },
    openLootbox: (state, action) => {
      state.lootboxOpenLoading = true;
    },
    checkBuyBronzePassStatus: () => {
    },
    checkBuySteelPassStatus: (state, action) => {
    },
    checkBuySilverPassStatus: (state, action) => {
    },
    checkBuyStandardLootboxStatus: (state, action) => {
    },
    checkBuyExplorerLootboxStatus: (state, action) => {
    },
    checkBuyAdventurerLootboxStatus: (state, action) => {
    },
  },
});

export const {
  cancelLootboxOpenLoading,
  openLootbox,
  cancelLootboxLoading,
  setLootboxes,
  getLootboxes,
  checkBuyAdventurerLootboxStatus,
  checkBuyExplorerLootboxStatus,
  checkBuyStandardLootboxStatus,
  cancelPassesLoading,
  getPassesInfo,
  setPassesInfo,
  checkBuyTapPassStatus,
  checkBuyBronzePassStatus,
  checkBuySteelPassStatus,
  checkBuySilverPassStatus,
  cancelTapPassesLoading,
  setTapPassesInfo,
  getTapPassesInfo,
  buyTapPass,
  getSilverPassInfo,
  setSilverPassInfo,
} = storeSlice.actions;

const storeReducer = storeSlice.reducer;

export default storeReducer;
