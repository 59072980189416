import { createSlice } from '@reduxjs/toolkit';
import { boostMultiplier, superBoostMultiplier } from '../../constants';

const initialState = {
  initialLoading: true,
  loading: true,
  tapsLoading: true,
  rewardLoading: false,
  isBoostActive: false,
  isSuperBoostActive: false,
  token: null,
  user: null,
  ip: null,
  isUserExist: null,
  reward: {},
  items: {},
  balance: null,
  taps: null,
  maxTaps: null,
  boosters: null,
  usedBoosters: null,
  withoutCountry: false,
  country: null,
  comboData: null,
  comboLoading: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserWithoutCountry:(state)=>{
      state.withoutCountry = true;
    },
    getMainData:(state)=>{
      state.initialLoading = true;
    },
    setMainData:(state,{ payload: { dailyCheckin, availableTaps,balance,combo } })=>{
      state.reward = dailyCheckin;
      state.comboData = combo;
      state.taps = availableTaps.taps;
      state.maxTaps = availableTaps.maxTaps;
      state.boosters = availableTaps.boosters;
      state.usedBoosters = availableTaps.usedBoosters;
      state.paidBoosters = availableTaps.paidBoosters;
      state.balance = balance.balance;
      state.items = balance.items;
      state.initialLoading = false;
      state.loading = false;
      state.tapsLoading = false;
    },
    registerUser: () => {
    },
    getUserStatus: () => {
    },
    activateBoost: (state) => {
      state.isBoostActive = true;
    },
    activateSuperBoost: (state) => {
      state.isSuperBoostActive = true;
      state.paidBoosters = state.paidBoosters - 1;
    },
    getComboData: (state) => {
      state.comboLoading = true;
    },
    setComboData: (state, action) => {
      state.comboData = action.payload;
      state.comboLoading = false;
    },
    clearComboData: (state, action) => {
      state.comboData = null;
    },
    cancelComboLoading: (state) => {
      state.comboLoading = false;
    },
    deactivateBoost: (state) => {
      state.isBoostActive = false;
    },
    deactivateSuperBoost: (state) => {
      state.isSuperBoostActive = false;
    },
    setUserCountry: (state, action) => {
      state.country = action.payload;
    },
    setUserStatus: (state, action) => {
      state.isUserExist = action.payload;
    },
    setUserToken: (state, action) => {
      state.token = action.payload.token;
      state.ip = action.payload?.ip;
      state.country = action.payload?.country;
    },
    setUserData: (state, action) => {
      state.user = action.payload;
    },
    setUserBalanceWithItems: (state, {payload: {balance, items}}) => {
      state.balance = balance;
      state.items = items;
      state.loading = false;
    },
    getUserBalanceWithItems: (state) => {
      state.loading = true;
    },
    setUserBalance: (state, action) => {
      state.balance = action.payload;
      state.loading = false;
    },
    getUserTaps: (state) => {
      state.tapsLoading = true;
    },
    setUserBoosters: (state) => {
      state.usedBoosters = state.usedBoosters + 1;
    },
    cancelTapsLoading: (state) => {
      state.tapsLoading = false;
    },
    cancelUserLoading: (state) => {
      state.loading = false;
    },
    saveUserWallet: (state, {payload}) => {
    },
    getDailyRewardInfo: (state) => {
      state.rewardLoading = true;
    },
    setDailyRewardInfo: (state, {payload}) => {
      state.rewardLoading = false;
      state.reward = payload;
    },
    claimDailyReward: (state, action) => {
      state.rewardLoading = true;
    },
    cancelDailyRewardLoading: (state) => {
      state.rewardLoading = true;
    },
    recheckUserBalance: () => {
    },
    sendUserTaps: () => {
    },
    checkBuyRewardStatus: (state, payload) => {
    },
    clearUserBalance: (state) => {
      state.balance = null;
    },
    setUserTaps: (state, action) => {
      state.taps = action.payload.taps;
      state.boosters = action.payload.boosters;
      state.paidBoosters = action.payload.paidBoosters;
      state.usedBoosters = action.payload.usedBoosters;
      state.tapsLoading = false;
    },
    increaseUserBalance: (state, action) => {
      if (state.taps > 0) {
        state.balance = state.balance + action.payload;
        const newTaps = state.taps - ( state.isBoostActive ? boostMultiplier : state.isSuperBoostActive ? superBoostMultiplier : 1 );
        state.taps = newTaps < 0 ? 0 : newTaps;
      }
    },
    checkBuySuperBoosterStatus: (state, payload) => {},
  },
});

export const {
  setUserWithoutCountry,
  checkBuySuperBoosterStatus,
  getMainData,
  setMainData,
  activateSuperBoost,
  deactivateSuperBoost,
  clearComboData,
  cancelComboLoading,
  getComboData,
  setComboData,
  setUserCountry,
  activateBoost,
  deactivateBoost,
  claimDailyReward,
  setUserBalance,
  setDailyRewardInfo,
  registerUser,
  cancelTapsLoading,
  saveUserWallet,
  getUserStatus,
  setUserStatus,
  setUserToken,
  setUserData,
  setUserBalanceWithItems,
  getUserBalanceWithItems,
  getUserTaps,
  recheckUserBalance,
  sendUserTaps,
  clearUserBalance,
  cancelUserLoading,
  increaseUserBalance,
  setUserTaps,
  setUserBoosters,
  getDailyRewardInfo,
  checkBuyRewardStatus,
  cancelDailyRewardLoading,
} = userSlice.actions;

const userReducer = userSlice.reducer;

export default userReducer;
